import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Is moving tax deductible?</h1>

        The costs of moving are tax deductible under certain circumstances. Your employer reimburses some of the costs of moving if you are doing so at the behest of your company. What is given here is only general information and for any specific circumstance it is better to consult a tax professional.
        <br/><br/>

        Companies have more than one way of doing it. They might pay the moving company directly instead of handing over the cash to you personally. This also exempts you from paying any taxes to the government as such expenses do not come under the purview of your total wages on the Form W-2. It is not taxable, if you have to return any excess over and above your moving costs. But it is necessary to fill in the Form 3903 for Moving Expenses for them to deduct such costs. However it is better if all moving expenses are covered by your employer, including storage, temporary living as well as house-hunting expenses, not usually deductible. And if your boss happens to be generous enough to give you a gross advance payment  or even a month’s pay as extra, you can safely cover the taxes if any, for the additional income.
        <br/><br/>

        Do not expect all moving expenses to be reimbursed. So it is better to find out other tax deductible expenses that are not reimbursed. Anyway, whatever the deduction, you don’t get a full reimbursement; it depends on your tax bracket. Most income taxes are similar whether federal or state in nature and you can regain some of the expenses this way.
        <br/><br/>

        If you don’t include the reimbursements allowed by your company in your taxable income, then such expenses are not deductible. But if the employer refrains from reimbursing any of the expenses, or if you are self-employed, you can deduct certain items like moving household goods and personal effects, cost of re-connecting disconnected utilities and shipping charges for your car and pets. Also if you are moving within the country, storage expenses while you are in transit up to 30 days can be deductible. Besides these, moving costs to the new home by the most direct route and lodging expenditure while in transit are also tax deductible. This also includes parking or toll fees while on the way. And if you want to fly to your new home the airfare is deductible.
        <br/><br/>

        To take full advantage of all the said deductions, you’ll have to keep all receipts related to the move. If you use your check book or credit card, every such transaction is recorded. Be sure to make a note of all moving expenses that are not covered by receipts such as mover tips etc.
        <br/><br/>

        Take some time to develop a good strategy by making your tax savings to the maximum, at the same tome minimizing any out of the way expenses. Consult a tax professional to reach the optimum solution.
        <br/><br/>
        
        A final word of caution: Never make a moving expense deduction and a business expense deduction at the same time for the same expense
      </div>
    </div>
  );
}