import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Different types of moves</h1>

        <ul>
          <li>Interstate Moves: An interstate move is a move that crosses a state boundary.</li>
          <li>Intrastate Moves: An intrastate move is a move that does not cross state boundary.</li>
          <li>International Moves: An international move is a move that crosses a country’s boundary.</li>
        </ul>

        These are the three different types of move. They also have other distinct differences. For example, intrastate moves are regulated by the state which they are carried out in. Any liability that the mover covers is usually also less in an intrastate move. The way that you have to pay is often also different, but that varies. In an interstate move, the customer will usually pay based on the weight and distance of the move. In an intrastate move, the customer will usually pay for trucks and people. This could be similar to renting a truck; you would pay a cost based on the number of hours. But, if you travel over a certain distance, you will usually have to pay more.
        <br/><br/>

        Every one at some point of other has to shift to a different place. This means shifting your household items from one place to another. However, it can be a complex and cumbersome task. Thus, it is essential that you a hire a moving company for shifting or transferring your household materials. There are numerous companies that offer moving services. Thus, you should select the best moving companies.
        <br/><br/>

        Before you select a moving company, it is necessary that you do a thorough research of the services and fees of the moving company.
        
        <ul>
          <li>Compare the fees or charges of various companies. You can also talk to your relatives and friends and inquire about the moving company they used.</li>
          <li>Better Business Bureau is the agency which gives ratings to the moving companies. You can always check with the Better Business Bureau to select with the best company.</li>
          <li>Also check, if the moving company is a constituent of AMSA Certified Mover Program. The member moving company follows the terms and agreements of the standard moving rules laid down by the program.</li>
          <li>In addition to checking the rates of a moving company, take into consideration your budget as well.</li>
          <li>In case, you are shifting to a distant place, make sure that the company provides you with a book on consumer’s responsibilities and rights.</li>
          <li>Also, the moving company should be licensed, regulated and insured. For that you can check the Department of Transportation or Public Utility Common.</li>  
          <li>It is advisable that you never hire a broker. A broker always sells to an expensive company.</li>
          <li>A moving company should be chosen as per your circumstances and not cost. This is because, many companies offer shifting on hourly basis. However, this can be an expensive task. Thus, do thorough research work regarding the basis on which they charge.</li>
        </ul>
      </div>
    </div>
  );
}