import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Important packing materials</h1>

        <b>Boxes/Cartons:</b> Make sure you have enough boxes to pack your belongings. Make sure that the 
        boxes are in good condition. Start collecting cartons from your local stores. Liquor stores have 
        boxes with dividers that are perfect for cups/glasses. Save boxes from materials you have purchased. 
        Also, it is an excellent idea to begin collecting boxes as soon as you are sure you want to move.
        <br/><br/>

        <b>Wrapping Paper:</b> Things like newspaper, bubble wrap, and etc are important to keep your items protected. 
        Use the cheapest available type of wrapping paper that you can to keep things protected. Some objects should be 
        packed with bubble wrap while newspaper is enough for other objects.
        <br/><br/>

        <b>Plastic Tape:</b> Make sure you have thick plastic tape to seal your boxes. The tape should be approximately 2 
        inches wide in order to make sure the boxes are closed properly. 
        <br/><br/>

        <b>Permanent Marker:</b>  Permanent markers are necessary to mark your boxes. Write things like “Fragile” Or “Kitchen Supplies” 
        to make sure you can distinguish your boxes. It may also be a good idea to write this in many places on the box. Finally, you 
        should also make an arrow to make sure you know what direction you should open the box from.
        <br/><br/>

        <b>Optional Supplies:</b>
        <ul>
          <li><b>Space Bags:</b> You can store clothing, blankets, and comforters in these bags. They condense your materials so they take less space 
          and also help protect them. </li>
          <li><b>Garment Boxes (Wardrobe Cartons):</b>You can buy these online or from your mover. They allow you to move your closet with the 
          hangers still attached.</li>
          <li> <b>Specialized Boxes: </b>You can also purchase these from your mover. Unfortunately, you will probably need these because things like 
          mattress cartons are hard to find anywhere else.</li>
          <li><b>Strapping:</b> Some of your materials may need to be strapped down so strapping supplies are also a good thing to have.</li>

      </ul>


      </div>
    </div>
  );
}