import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Corporate relocation tips</h1>

        When you are moving due to corporate relocation, you need to carefully pack everything so that date and security are not compromised.
        <br/><br/>

        As part of the overall relocation plan, you should also be ready with a plan. The following tips should help you.
        <br/><br/>

        A reliable moving company that has experience of managing such complex moves will help you plan your packing and relocation in a more effective manner. 
        <br/><br/>

        You may want to get rid off outdated equipment and have it replaced.
        <br/><br/>

        Your system administrators need to determine whether you can safely and securely install electronic devices and equipment. Find out if there is any incompatible equipment.
        <br/><br/>

        At the new place, you should have a layout based on your specific management plan and your assets.  This will involve things like doing a CAD layout for your office systems and furniture at the relocated space. Work out exact outlets for phone/fax, electric connections.
        <br/><br/>

        Your in house relocation team should work in tandem with your cabling & contract furniture partner and you have to make sure that there is proper coordination. All this needs to be done without affecting the regular workday.
        <br/><br/>

        You should leave a suitable window of recovery for testing/troubleshooting of systems after reconnection happens at the new location. This should be done under the watchful eye of service technicians.
        <br/><br/>
        
        The key to a successful technology transfer/relocation plan is coordinating the technology moving process with other components of moving process.
      </div>
    </div>
  );
}