import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Security</h1>

        Your furniture, clothing, books, antiques and any other possessions you have in your home are secured behind closed locked doors or fences and most likely you have an alarm on your home also. The least you need to gain entry to your home is a key or the alarm code and only a few select people like your family have this. You go to all this trouble in order to protect your valuables from being stolen from your home. When you are looking in to storing your possessions you will also need some security to protect the items from theft. The number one concern of the storage facility should be the security of your goods while in storage. You can decide upfront based on the value of the goods what security arrangements you feel comfortable with and you can choose to only visit the facilities that meet your needs. In fact many people choose a facility based on the value of their goods and the level of security offered by the storage facility.
        <br/><br/>
        Different facilities offer different levels of security so check out the security arrangements when visiting the facility initially. The levels of security differ greatly depending on the facility so decide upfront what the minimum security arrangements you would be comfortable with are. Do you feel that 24 hour on site security is a must or is an alarm sufficient to meet your needs? The following are some of the security arrangements facilities will have so use these as a guide when questioning the facility on their security arrangements.
        <br/><br/>

        <ul>
          <li>Fences - Metal picketed fences or complete brick walls offer some protection from trespassers. The brick walls offer greater protection from prying eyes from seeing in to the facility grounds.</li>
          <br/>
          <li>Coded Gates – A wall or fence without a similarly secure gate to gain access is worthless. Coded gates can be very effective against undesirables gaining access to the facility grounds and buildings. Coded gates are especially useful to prevent unauthorized access as only employees and customers of the facility will have the codes. Access can be logged each time people come and go and a record can be kept.</li>
          <br/>
          <li>Motion sensors can be used both indoors and outdoors for extra protection and can be connected up to light fittings or alarms or both. The light will automatically turn on when motion is detected and the alarm will sound. The alarm can be set up to notify the police so they can investigate.</li>
          <br/> 
          <li>Alarms- Alarms are a great crime deterrent anywhere including your home, office and at your storage facility. The alarms can be connected up to the local police station or may simply sound when someone has broken in to the facility. Fire alarms are another security measure to protect your goods from being destroyed by fire and these are generally connected up to the local fire or police station.</li>
          <br/>
          <li>Keypad access offers great security and peace of mind to the person storing possessions. You will be given a code to get to your unit which may be use to give you access to hallways, elevators and even your floor. If you are in a multistory unit you may have to use the code in the elevator to gain access to the floor your goods are stored and you may not be able to go on to another floor, this adds an extra level of security.</li>
          <br/>
          <li>Lighting – Good outside and inside lighting. Lighting can be motion sensitive and is thus triggered by some type of movement or it can be permanent. Lighting can help deter undesirables from coming on to the storage facilities property.</li>
          <br/>
          <li>Locks – You will most likely be responsible for obtaining your own lock for your storage unit and will therefore have total control over who can access the unit. It is a good idea to leave a key with the warehouse operator in case of emergency. It is also a good idea to let the storage facility know if you plan top give cope of the key to other people that you know will need to gain access at some time. Some storage facilities may ask for a list of these people and may give them access when they need or may contact you prior to giving them any access. All facilities are different and many will have different rules regarding access.</li>
          <br/>
          <li>On site Guards – Some facilities may have on site security twenty four hours a day seven days a week and others may have a resident manager who doubles as a guard during off hours.</li>
          <br/>
          <li>Close Circuit Television (CCTV) – This is an excellent way to monitor who is going where and what they are doing in the facility. In most cases a person will be monitoring these CCTV’s and in some cases this is video taped to be looked at a later time.</li>
          <br/>
          <li>Hallway Intercoms- This is another excellent way of both summoning help and in advising people to move away from areas that they should not be in or that are restricted.</li>
        </ul>

      </div>
    </div>
  );
}