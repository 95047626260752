import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>General Packing Tips</h1>

        <ul>
          <li> <b>Begin collecting boxes as soon as you decide your going to move:</b> If you have already saved boxes, you won’t have to find boxes 
          in a hurry to save money on packing.</li>
          <li><b>Make sure you begin the packing process ASAP. </b>Start packing up areas of your home that you don’t frequently use. Also, start packing 
          up things that you don’t frequently use such as fine china.</li>
          <li> <b>Mark what you Pack:</b> As you pack a box, write what it contains like, “KITCHEN SUPPLIES” and also, write if the box contains 
          fragile supplies. Finally, make an arrow so you know where the top of the box is. Note: You might want to write this information a 
          few times on one box. </li>
          <li> <b>Use Garment Boxes (Wardrobe Cartons) for hanging clothing. </b>Garment boxes will protect suits and other hanging clothing. T
          hey are the best option for avoiding any damage to these types of clothes. You can purchase these from the movers or online if you want 
          to pack on your own.</li>
          <li> <b>Empty your cabinets and dressers.</b> Cabinets and dressers often become too heavy to move if their content is still inside them. 
          Movers will often not move these unless you remove the contents.</li> 
          <li> <b>Fill your boxes to their maximum capacity.</b> There is no use leaving any space in your boxes. Fill them up to their maximum capacity 
          in order to save space and save money.</li>
          <li> <b>Packing your Tools. </b> Make sure all tools are drained of all oil and gas before they are moved. It’s okay to keep them in their 
          toolboxes while they are being moved.</li> 
          <li> <b>MAKE SURE THAT NOTHING YOU PACK IS FLAMMABLE OR EXPLOSIVE.</b> Anything flammable is potentially dangerous to your move. 
          Your risk your belongings and the mover’s well being by moving anything hazardous.</li>
          <li> <b>AVOID ANYTHING THAT COULD POSSIBLY LEAK OR SPILL.</b> If something spills and causes damage, not only are your goods ruined but 
          your warranty is null and void. Basically, you would be paying for the warranty but, it would not be applicable to you. Make sure you 
          don’t pack anything liquid so you can avoid this nightmare.</li> 
        </ul>

      </div>
    </div>
  );
}