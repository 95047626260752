import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Storage Tips</h1>

        Storage generally means to reserve or put away for future use. People today like as little clutter as possible in their 
        homes and place of work. Easily accessible public storage offers a solution to this problem. Many storage units are 
        climate controlled, have 24 hours security and pest control programs that are the best in the industry, all in an 
        effort to provide secure safe storage for your belongings. Goods can be stored for as long or as short a period as 
        you need and spaces very in size so you can pick a space that will hold all your goods and not have to pay for unused space.
        <br /> <br />

        <ul>
          <li><a href='/storage/storage_considerations'> Storage considerations </a> </li>
          <li><a href='/storage/self_storage'> Self storage </a> </li>
          <li><a href='/storage/deciding_on_storage'> Deciding on storage </a> </li>
          <li><a href='/storage/security'> Security </a> </li>
        </ul>



      </div>
    </div>
  );
}