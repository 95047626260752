import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Saving/storing/retrieving your vital business data during relocation</h1>

        Here are some of the key steps to be taken for successful storage and transfer of your crucial data during corporate relocation.
        <br/><br/>
        A successful relocation needs a synchronized team effort to pay necessary attention to technology. Involve not only your facilities in-charge, but also your IT & HR managers.
        <br/><br/>
        Here are some guidelines for a smooth transfer of business data during relocation

        <ul>
          <li>Work out a comprehensive technology plan to understand how much and what types of data you have stored in your network or individual machines. Find out which files are important and sensitive and which files are unnecessary and disposable.</li>
          <li>When storing, saving, securing and transferring data, you might be required to outsource some of the tasks since all of them can not be managed in house.</li>
          <li>Devise an asset management plan in the domain of technology for knowing what must be retained and what can be done away with.</li>
          <li>If you want to outsource the entire move, make sure you pick a moving company that has had success with the process in the past</li>
        </ul>
        
        Ideally, ask for some references. Find out how well moves went and see if the moves we on the same scale as yours. 
        <br/><br/>

        Involve your technology outsource partners in the process right from the start to derive the maximum benefit of their skill and expertise – in planning and execution.
        <br/><br/>

        If you are refreshing/upgrading technology during or after relocation and/or planning to order new furniture systems, leave enough delivery lead times for an appropriate move date delivery to avoid delays or work stoppage.
        <br/><br/>

        While scheduling the move dates, make sure you leave time for data backup and other services.
      </div>
    </div>
  );
}