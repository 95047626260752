import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Deciding on storage</h1>

        Here are some important factors to keep in mind when making your storage decision:

        <ul>
          <li>Location – if you choose a self storage facility, consider its’ location to your residence and the time and expense associated with frequent visits to the location. Many consumers find that storage facilities located closer to their residence better meet their needs. If you choose a mobile storage option, you may choose to maintain your mobile storage unit on your own property or have it stored in a storage facility or another location.</li>
          <br/>
          <li>Security – understand the security in the storage facility that you choose. How will your household goods be stored within the storage unit? What kind of security does the facility have both during regular business hours and after hours? If you choose a mobile storage option, understand how your storage unit will be secured.</li>
          <br/>
          <li>Convenience – While the location of the facility is important, you may also be interested in the accessibility of your storage facility on weekends and evenings.</li>
          <br/>
          <li>Size - Most storage facilities and mobile storage options will offer a wide range of storage options for you. Discuss your needs, both current and future, and the facility can help you to find the storage unit size that best matches your storage needs and your budget.</li>
          <br/>
          <li>Price – Storage facilities can have a range of prices for their storage units, depending on the size of the unit, the length of time that you will be renting the storage unit and their own marketing objectives. Storage facilities operate in many ways like other real estate options. They are seeking to optimize the use of their available space. They fuller they are at a given time, the less likely they will have special offers or discounts available. Mobile storage companies will often provide incentives for longer rental of their storage units.</li>
          <br/>
          <li>Insurance – Ask about what would happen to your stored items in case of fire, flood or other natural disaster. Storage facilities will have their own insurance but it is important to understand what would happen in case of a disaster, especially if you plan to be storing high value items.</li>
          <br/>
          <li>Special Needs – Many locations offer storage units that are climate controlled or provide other special storage accessories. Just discuss your needs with your sales representative and he or she will help you to find the best option for you.</li>
        </ul>

      </div>
    </div>
  );
}