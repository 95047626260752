import { LOAD_MOVE_FORM } from '../actions/index.js'; 

const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_MOVE_FORM:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
