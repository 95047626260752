import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>How to avoid rogue movers</h1>

        Your mover has left with all your belongings locked up in his van. Soon you are at your new home and unpack everything and find to your dismay that some cherished items are missing. Obviously your belongings were entrusted to a rogue mover. What follows is a nightmare: police, lawyers, courts! Not a very good scenario.
        <br/><br/>

        If you had chosen a reliable moving company this wouldn’t have happened. So what should one do under the circumstances?
        <br/><br/>

        You can check the websites of moving companies. Using the background information derived from these, make a list of potential companies. Use the contact information provided to ask them questions and get relevant information. This way you also get a written response which they can’t deny later.
        <br/><br/>

        Also ask your friends and colleagues who have moved before. Some may recommend a company while some will ask you to avoid a company. Make a list of both of them as both are important; evaluate them before making the final choice. Websites like MovingScam.com can also be useful for they reveal moving scams, so that you are forewarned. Use their message board, where persons post their experiences. You can also post questions and get answers from all around. The Better Business Bureau also can help for they make reports in response to complaints filed by unhappy customers. A company may have such customers and notice why the complaints were made and whether they were resolved to the satisfaction of the complainants. You can come to a decision by evaluating such factors.
        <br/><br/>

        Going through The Federal Motor Carrier Safety Administration website is a good idea. They will list all movers who have Department of Transportation's numbers which show that the company is registered with the Department. FMCSA lists some signs to help you to spot a possible rogue mover.
        <br/><br/>

        Consider a mover a rogue if he sends you an estimate without an on-site inspection of the goods to be moved. It is not a good sign if the moving company demands cash or a hefty deposit in advance. Federal regulations require a mover to provide you with a copy of Your Rights and Responsibilities When You Move booklet before you make an interstate move. If this is not provided then he may not be okay. A rogue mover’s website usually doesn’t have a local address or information on insurance or licensing. If a company says all your possessions are covered by their insurance, score them off your list.
        <br/><br/>
        
        When you ring the mover they must answer with the name of their company and not just ‘movers’ or something. If the office or warehouse is in poor shape or do not exist at all, count them out. If a rented truck and not a company marked fleet truck arrives at your front door on the day of moving it is no good at all. If you don’t feel right about a company, if things are not on the up and up the company is probably a rogue mover.
      </div>
    </div>
  );
}