import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>An experienced piano mover makes all the difference</h1>

        Moving from one place to another is a tough task. It is very difficult to move household belongings and furniture without any damage. Shifting your treasured piano is still very difficult.
        <br/><br/>

        You need services of an experienced moving company to handle your precious instruments like a piano.
        <br/><br/>

        There are few important points you must keep in mind while selecting a moving company for piano. Many companies specialise in handling different items, say piano, or furniture or computers. The moving company which specialises in handling piano, will have more experienced labour and equipment to handle it. You should ask few questions like what precautions the moving company takes in handling a piano, or how many pianos it handles everyday. This will give you confidence whether the company is well experienced.
        <br/><br/>

        It is better to have a look at the vehicle which will move your piano. Vehicle with good suspension and temperature control will ensure safe shifting of piano. Also ensure that place where you will store the piano has proper temperature control. Piano gets damaged if there are wide fluctuations in the temperature.
        <br/><br/>

        You will find many companies offering services at cheapest rates, but that won’t guarantee safe moving of your piano. Ensure that your piano mover is licensed and insured so that you do not run the risk of monetary loss in case of an accident.
        <br/><br/>
        
        If you take note of above tips, you will be buying peace of mind in shifting your piano.
      </div>
    </div>
  );
}