import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Payment and claims</h1>

        Movers will usually collect charges before the goods are unloaded. You should confirm the method of payment with the mover beforehand.  Cash or Check is the preferred payment method for most movers but some may also accept credit cards. If you do not pay at delivery, your mover has the right to hold your items until he is paid. This will increase charges because the mover is acting as a storage facility on your behalf. So, make sure that you have the money when the mover arrives so that you do not have to pay any extra charges and so you get your shipment at your desired time.
        <br/><br/>

        If you chose a <b>non-binding estimate</b>, the items will be weighed and you will have to pay based on the weight. 
        But, the mover cannot increase the price past a certain extent because regulations state that you will not have to pay more than 110% 
        of the cost provided on the <b>non-binding estimate</b>. If you chose a <b>binding</b> or a <b>not-to-exceed estimate</b>, then you will 
        pay the amount provided. In the case of a <b>not-to-exceed</b> estimate, you will pay based on either the weight or the estimate, whichever one is less.
        <br/><br/>

        Movers strive to be as cautious with your things as possible. But, sometimes one or two items may be damaged or be missing. Verify all of your items and make a memo on the inventory if anything is missing or damaged. File a claim within 9 months of the delivery and make sure the item is still in its crate almost untouched so that the mover can see the damages (if it’s damaged). Based on the amount of liability/insurance you paid, you should get money back if the mover feels the claim is reasonable. Keep in mind that the mover is not accountable for any damage that was done to an item in a crate that you packed.
      </div>
    </div>
  );
}