import React from 'react'; 

export default(props) => {
  return(
    <div className = 'white_panel'>
      <div className = 'container panel'>
        <h3>Save more on your move with Merak</h3>
        <h4><small class="text-muted">Save over 65% - $699 Move Deals</small></h4>
        <p>We’ve been helping tens of thousands of customers like you get the best moving deals possible for over 10 years. 
        With Merak, we save you the hassle of contacting movers and bring them over to you. 
        You’ll get the choice of using your favorite provider from our nationwide fully licensed and insured mover network.
        We'll help you save money, time, and make sure your move is handled by a trusted professional. </p>
      </div>
    </div>
  );
}