import { combineReducers } from 'redux'; 
import { reducer as formReducer } from 'redux-form'; 
import ZipReducer from './zip_reducer';


const rootReducer = combineReducers({
  zip: ZipReducer,
  form: formReducer
}); 

export default rootReducer; 