import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Self storage</h1>

        There are many reasons why people need to store goods away for a period of time to be used at a later date. In today’s world people like as little clutter as possible in their homes. What better way to remove this clutter and still hold on to your valued possessions than self storage. Self storage offers a safe, secure and clean environment to store your goods. Generally 24 hour access to your goods is allowed and on site security with many offering CCTV is fast becoming the norm in this highly competitive industry. Most storage warehouses offer climate controlled options to store such environmentally sensitive items as books and fur coats. Books and all paper products are highly sensitive to high humidity levels and furs generally nee to be stored in the cold to prevent fur degradation. Couple all of these with sound pest control programs and you are talking with the standard service warehouse offer today.
        <br/><br/>

        <b>What can I store?</b>
        <br/>

        Almost anything can be stored for a certain period of time in the correct environment. Perishable food is an exception to this and will not be accepted by a warehouse for storage. The following list gives you some idea of what items can be stored in the correct environment with no negative effects.

        <ul>
          <li>Computers</li>
          <li>Home furnishings</li>
          <li>Antiques</li>
          <li>Furs– You may want to store summer garments in winter and vice versa</li>
          <li>Linens</li>
          <li>Mattresses</li>
          <li>Electronic equipment</li>
          <li>Musical instruments</li>
          <li>Pianos</li>
        </ul>

        Homes are getting smaller and smaller and real estate prices are increasing all the time and because of this many people are downsizing and putting extra furnishing in to storage. You may just need to store items that you do not want or can not have in your home due to space constraints and public storage can be an excellent option in situations like this
        <br/><br/>

        Sometimes when people move, their household goods may need to be stored temporarily for a period of time. This can be due to many reasons including not having located a permanent home in the new city or town or deciding to do some remodeling before moving in to the new home. Sometimes people will decide to build a completely new home in the new city and if you decide to take this route you will need to have your furnishings out of the way while this is going on and storage is an obvious choice. If you are moving and you have household goods you need stored, your moving company may have a storage option available to you which may mean you will not need to seek outside self storage. It is best to check with your moving company to determine if this service is available and what the cost would be. An advantage to storing goods with your moving company is that the company will then deliver your goods and any other possessions when you are ready for them. You should also ask if you can go and collect any items from storage at any time and if you have to give any notice to do this. This may be especially important to businesses where important business records are being stored. The truck your business or household goods are transported in may be another storage option especially if you only need your goods stored for a few days. Be sure to back the trailer up to a wall so nobody can break in and park in a safe area.
        <br/><br/>

        Families today frequently have more than one vehicle and may have a boat also that needs to be stored in the winter months. You may not have a big yard and even if you do you may not be allowed to store your boat on your property. In some cities the local ordinance does not allow boats to be store in a residential neighborhood.
        <br/><br/>

        Many storage units are climate controlled, have 24 hours security and pest control programs that are the best in the industry, all in an effort to provide secure safe storage for your belongings. Goods can be stored for as long or as short a period as you need and spaces very in size so you can pick a space that will hold all your goods and not have to pay for unused space. If you use storage a lot it is advisable to get a space a little bigger than you actually need as you will probably fill it up pretty quickly.
        <br/><br/>

        Many storage operations offer a multitude of other service including truck rental or even a free truck for a period of time in order to get your business. Many companies have workers who will come to your home and pack up your goods professionally, load up a truck and drive it to the storage unit. They will then unload your goods and pack them in to the storage space efficiently. Remember these people are professionals and can do the job more effectively and efficiently than you may be able to. Of course this convenience come with a fee however it may be worth it for the peace of mind.
        <br/><br/>

        <b>Important Questions to ask yourself before deciding to spend money on storage</b>
        <br/>

        At the time when you are making a decision on whether to store or not to store your goods, think about the other options that may be available to you. Do you really need all the items you are planning to put in to storage? Can you get rid of any without any regrets? Remember if you are moving that everything you get rid of now is something you won’t have to move yourself or pay to have moved. On the other hand you may need it someday! Ask yourself some questions before deciding whether you want to store particular items or not.
        
        <ul>
          <li>Do I need the item?</li>
          <li>Will I ever use this item again?</li>
          <li>When did I use it last?</li>
          <li>Has it got sentimental value?</li>
          <li>If any of the items need repair will you really have them repaired?</li>
          <li>Clothing items – if you have not use any item will you really ever wear it again? </li>
        </ul>

        <b>Throw away, give away or sell!</b>
        <br/>

        You may decide after answering the question above that you do not really need some of the items. The next big question is what to do with all this stuff. There are many options available to you. You could throw the stuff out, give it away to charity or have a garage sale.
        <br/><br/>

        Household items could go to charity organizations such as St Vincent de Paul or the Red Cross and any books could go to a local library or school. Remember to get a receipt for tax purposes.
        <br/><br/>

        If you decide to have a garage sale be sure and price the items so they will sell, remember that making a profit is secondary in this instance and getting rid of your junk is the primary reason for the sale. Here are some important points to remember as you plan your garage sale.

        <ul>
          <li>You may need a permit in some towns to have a garage sale</li>
          <li>Have the sale on the weekend when people are off work- Saturday mornings are best</li>
          <li>Put up sign around the neighborhood a few days before hand to let people when and where the sale is</li>
          <li>If someone makes an offer less than the advertised price barter to bring the price up</li>
          <li>Remember to tell neighbors relative and friends about the sale</li>
        </ul>

      </div>
    </div>
  );
}