import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Packing tips for major household items</h1>

        There can be an endless list of household items in an individual’s home. And when one decides to relocate to a new home, 
        one faces the difficulty of packing them all in proper order so that nothing gets damaged. 
        MerakMoving brings you an exclusive list of pointers and tips to make your packing process for major household items a lot more convenient 
        than you ever thought possible.
        <br/><br/>

        <b>Bureau Drawers</b>
        <br/>
        Avoid overloading items in a drawer to prevent it from damaging. Take away firearms (if any) that can either break or leak. 
        <br/><br/>
        
        <b>Cars and Motorcycles</b>
        If you are moving a motor vehicle like car or motorcycle, make sure they are well drained off for the fuel and are emptied before the move. 
        The batteries of motorcycles should also be removed off the connection. There should be an arrangement of automobile antifreeze done to protect 
        your vehicles on the moving van from severe cold during winter.
        <br/><br/>
        
        <b>Clocks</b>
        <br/>
        If you have a large clock with a pendulum, it is better to remove the pendulum off from the clock and keep it aside at a safer place. 
        Any grandfather clocks must be moved with the help of servicemen.
        <br/><br/>
        
        <b>Curtains and Drapes</b>
        <br/>
        You can hang drapes over crossbars in your wardrobe cartons, or you may also fold your curtains and pack them in clean cartons. 
        Alternatively, you may remove the curtains from rods, fold them, and pack them in either cartons or bureau drawers.
        <br/><br/>

        <b>Lamps and Lampshades</b>
        <br/>
        Pack lamps and lampshades individually in clean cartons with tissue filling. Bulbs and harps should be wrapped with packing paper and 
        taped within the inside corners of the carton. Shades can be wrapped in tissue paper, avoid using newspapers, and put them in large 
        cartons with tissue filling.
        <br/><br/>

        <b>Silverware </b>
        <br/>
        Any silverware item should be wrapped in a cloth or a low sulphur content paper to prevent it from getting tarnished. An old blanket can 
        also be used for preventing the silverware chest from any scratches.
        <br/><br/>

        <b>Tools </b>
        <br/>
        Any power tools should be transported only after draining the fuel from them and should be packed in small but strong cartons. 
        Valuable tools should be packed in separate cartons.
        <br/><br/>

        <b>Waterbed Mattresses </b>
        <br/>
        All waterbed mattresses should be drained off from water and folded by 20 inches at a time, ensuring no creases are made across each fold. 
        Avoid placing them in sharp objects.
        <br/><br/>


      </div>
    </div>
  );
}