import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Packing up your living room</h1>

        <b>*Note:</b> Remember that furniture and televisions will most likely be moved and packed by your moving company. Things like pool tables, television cabinets, and other large equipment will also be moved by your mover.

        <b>General packing tips for your living room:</b>
        <ul>
          <li>Pack books and manuals together in a small-medium sized carton. Make sure you have labeled the box clearly with any notations you may need</li>
          <li>Wrap artificial flowers and make sure you label their boxes with “FRAGILE”</li>
          <li>Make sure any speakers are well packed and cushioned.</li>
          <li>Expensive art should be well cushioned and will most likely be crated by your mover.</li>
          <li>Advice: Carry any items like photographs or family videos with you when you move.</li>
          <li>Expensive novelty items are also a good thing to carry with you when you move.</li>
          <li>Other electronics like printers and televisions will also most likely be move by your mover. Make sure you carry any wires or cords with you.</li>
          <li>Toys do not have to separately wrapped, just place them in a moving carton.</li>
        </ul>
    
        <b>Moving Lamp Shades</b>
        <ul>
          <li>Packing a lamp maybe hard because you will need large boxes. You may need to purchase cartons from the mover to pack there.</li>
          <li>Remove the lamp shade and the bulb. Wrap any cords to the base of the lamp.</li>
          <li>Use a box at least two inches larger than the lamp shade. Place extra wrapping paper (NO NEWSPAPERS) around the box for extra cushioning.</li>
          <li>Place the lamp shade inside the box and add extra cushioning, again no newspapers.</li>
          <li>If you have any smaller lamp shades, place them inside the larger lamp shade, just make sure that they are separated by a large amount of cushioning and no lamp shades touch.</li>
          <li>Wrap bases and lamps separately and place them in their own moving cartons. Make sure you write “FRAGILE” on all boxes.</li>
        </ul>

        <b>Packing Up Your Computer:</b>
        <ul>
          <li>Backup all the files on your computer, just to be safe.</li>
          <li>Disconnect all wires and label them for reconnecting at your new home.</li>
          <li>Disconnect the monitor and wrap it separately.</li> 
          <li>Pack Printers and faxes separately.</li>
          <li>Make sure that the computer is well protected and well cushioned.</li>
        </ul>
      </div>
    </div>
  );
}