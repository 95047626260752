import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Storage considerations</h1>

        Storage generally means to reserve or put away for future use. People today like as little clutter as possible in their homes and 
        place of work. Easily accessible public storage offers a solution to this problem. Many storage units are climate controlled, 
        have 24 hours security and pest control programs that are the best in the industry, all in an effort to provide secure safe storage 
        for your belongings. Goods can be stored for as long or as short a period as you need and spaces very in size so you can pick a 
        space that will hold all your goods and not have to pay for unused space.
        <br/><br/>

        There can be many reasons private people and businesses need to store goods away to be used at a later time. Sometimes when moving 
        your household goods may need to be stored temporarily. This may be due to timing and not having located a permanent home in your new 
        city and you may need to store your belongings for a few weeks as you find a new home. You may decide to do some remodeling of your 
        new home or build an entirely new home and you will need to have your furnishings out of the way while this is going on and storage 
        is an obvious choice. You may just need to store items you do not or can not have in your home due to space constraints and public 
        storage can be an excellent option in situations like this. If you are moving and your household or office furnishing need to go 
        in to storage for a period of time, your moving company may have a storage option available to you which may mean you will not need 
        to seek out self storage. It is best to check with your moving company to determine if this service is available and what the cost 
        would be. An advantage of storing with your moving company is that the company will then deliver your furniture and other possessions 
        when you are ready for them. You should also ask if you can go and collect any items from storage you may want at any time and if you 
        have to give any notice to do this. This may be especially important to businesses where important business records are being stored. 
        The truck your business or household goods are transported in may be another storage option especially if you only need your goods stored 
        for a few days. Be sure to back the trailer up to a wall so nobody can break in and park in a safe area.
        <br/><br/>

        Businesses today like to keep their overheads down and do not want to have to pay enormous fees for high rent office space above 
        and beyond what they will use everyday. You will pay the same per square foot for storage space in an office building as you will for 
        the office. Less expensive public storage is ideal for these businesses. If you are fortunate to work in a rapidly growing company your 
        business may need more storage space and self storage is an excellent secure and cost effective option. You will be in possession of a 
        key or access card and can control who has access to the space providing excellent security access for your items. Paper and computer records, 
        infrequently used equipment and even office furniture can be stored in public storage. Most storage companies offer climate controlled storage 
        to protect items that can be sensitive to the environment. Be sure to provide details of what items you need stored when requesting 
        estimates so that the storage companies can give accurate estimates. Different items may require different storage conditions so 
        they will need to know this when calculating the estimate.
        <br/><br/>

        If you are looking at storing items from the workplace, always check to find out if the company has a record storage retention policy or 
        if there is any governing regulatory requirement on the length of time you need to keep these records. The records may be obsolete or 
        out of date and you may be able to shred them and throw them out.
        <br/><br/>

        At the time when you are making a decision on whether to store or not to store your goods, think about the other options that may be available 
        to you. Do you really need all the items you are planning to put in to storage? Can you get rid of any without any regrets? Remember if you are 
        moving, that everything you get rid of now is something you won’t have to move yourself or pay to have moved. On the other hand you may need it 
        someday! Ask yourself some questions before deciding to store particular items or not:

        <ul>
          <li>Do I need the item?</li>
          <li>Will I ever use this item again?</li>
          <li>When did I use it last?</li>
          <li>Has it got sentimental value?</li>
          <li>If any of the items need repair will you really have them repaired?</li>
          <li>Clothing items – if you have not use any item will you really ever wear it again?</li>
        </ul>

        You may decide after answering the question above that you do not really need some of the items. 
        The next big question is what to do with all this stuff. There are many options available to you. You could throw the stuff out, 
        give it away to charity or have a garage sale.
        <br/><br/>

        Household items could go to charity organizations such as St Vincent de Paul or the Red Cross and any books 
        could go to a local library or school. Remember to get a receipt for tax purposes.
        <br/><br/>

        If you decide to have a garage sale be sure and price the items so they will sell, remember that making a profit is secondary in this 
        instance and getting rid of your junk is the primary reason for the sale. Here are some important points to remember as 
        you plan your garage sale:

        <ul>
          <li>You may need a permit in some towns to have a garage sale.</li>
          <li> Have the sale on the weekend when people are off work- Saturday mornings are best.</li>
          <li> Put up sign around the neighborhood a few days before hand to let people when and where the sale is.</li>
          <li> If someone makes an offer less than the advertised price barter to bring the price up.</li>
          <li> Remember to tell neighbors relative and friends about the sale.</li>
        </ul>

      </div>
    </div>
  );
}