import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Long distance moving companies - service at your doorstep</h1>

        Moving locally is a tough job. Moving long distance is still a tougher job. But in today’s hectic life of professionals this has become a routine. This year you are in New York, next year you may be asked by your employer to shift to Arizona. But not to worry now. There is some one to take care of all your worries and head aches. Long distance moving companies can make your long distance moving a pleasant experience.
        <br/><br/>

        A long distance moving company provides you guaranteed pick-up and prompt delivery of your belongings at distant location. In order to give best services to the client, long distance moving companies employ well maintained trucks and tractor-trailers. Entire movement of the vehicle is tracked with the help of sophisticated system of GPS tracking. You can get minute to minute feed back of movement of the vehicle carrying your belongings. You will be informed when exactly the vehicle will reach your destination.
        <br/><br/>

        The drivers are very familiar with the distant locations. They ensure safe delivery of goods. They are polite and very cooperative.
        <br/><br/>

        Apart from moving the goods, company’s quality assurance representatives assist you in solving any of your problems or queries related with the handling.
        <br/><br/>
        
        Hire the services of company to pick up your precious belongings and deliver at your new home.
      </div>
    </div>
  );
}