import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>No more loading headache!</h1>

        Now you do not have to worry about how to move your belongings to your new home, because Merak Moving brings you a handful of tips on loading your household items.
        <br/><br/>

        <b>A Safe and Secure Loading</b>
        <br/>
        To load your heavy goods, use a ramp. You can always look for complimentary ramps often offered by moving companies. They also offer moving platforms with a range of packing supplies that can be purchased from their stores too. During your research for a good moving company, find out the moving time and make sure everything is loaded in time. While handling heavy items, you must bend on your heels and carry them with your knees and not your back.
        <br/><br/>

        <b>A Smarter Move</b>
        <br/>
        Before you get prepared for loading your things, you must have a preset plan in mind to lessen loading time and exhaustion. A pre-planned order will also help in the safer transit of your belongings.
        <br/><br/>

        Before you load your goods, put a piece of cardboard or a plastic sheet on the floor of the trailer to protect them from any scratches or damage. You may also cover your shipment with cover sheets or plastic sheets to protect them from dirt or dust during transit.
        <br/><br/>

        Always load the heavy appliances first on the floor against the trailer’s front wall and then towards the front wall of the trailer and on the floor. Put the light-weighed appliances once you have loaded the heavy items.
        <br/><br/>

        Items with long dimensions, such as sofas, can be placed upright on end with other large heavy items on its either sides. Mark the cartons with heavy and fragile items for easy identification and security purpose. All items should be fastened properly to prevent damage from movement.
        <br/><br/>

        Take special care of cloth materials such as blankets and quilts. You may place cardboard under items that can be soiled on the floor of the trailer. Mattresses and upholstered furniture can be loaded off on the floor. Gaps and spaces can be filled up well with bagged pillows and furniture cushions.
        <br/><br/>
        
        In case it is required, you can also make use of blocks and braces during your loading process in order to keep things steady. Make use of large sheets of plastic paper to protect your shipment from dirt and dust.
      </div>
    </div>
  );
}