import React from 'react'; 

export default(props) => {
  return(
    <div id="how_it_works">
      <div className = 'container panel'>
        <h2>How It Works</h2>
        
        <div className="row justify-content-md-center">
          <div className="col-md-3">
            <i className="fa fa-list-alt" aria-hidden="true"></i>
            <p> Fill out quick information about your move. </p>
          </div>

          <div className="col-md-3">
            <i className="fa fa-address-book-o" aria-hidden="true"></i>
            <p> Wait for our nationwide licensed and insured movers to contact you. </p>
          </div>

          <div className="col-md-3">
            <i className="fa fa-truck" aria-hidden="true"></i>
            <p> Compare quotes and save, pick your favorite mover, and start moving! </p>
         </div>


        </div>

      </div>
    </div>
  );
}