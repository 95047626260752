import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import normalizePhone from './normalize_phone';
import normalizeZip from './normalize_zip';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "Please enter a name";
  }

  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Please enter a valid email address";
  }

  if (!values.phone || values.phone.length < 12) {
    errors.phone = "Please enter a valid phone number";
  }

  if (!values.fromZip || values.fromZip.length < 5) {
    errors.fromZip = "Where are you moving from?";
  }
  if (!values.toZip || values.toZip.length < 5) {
    errors.toZip = "Where are you moving to?";
  }

  if (!values.size || values.size === "") {
    errors.size = "Please select a size";
  }

  if (!values.moveDate) {
    errors.moveDate = "When are you moving?";
  }

  return errors;
}


const renderFullWidthField = field => {
  const icon = field.faIcon ? `fa fa-${field.faIcon}` : "";
  const { meta: { touched, error } } = field;
  const className = `form-control form-control-lg ${touched && error ? 'is-invalid' : ''}`

  let iconSpan = null;
  if (icon) {
    iconSpan = <span className="input-group-prepend input-group-text" id="basic-addon1"><i className={icon}></i></span>;
  }
  return (
    <div className="form-group col-lg-12 has-danger">
      <label>{field.label}</label>
      <div className="input-group">
        {iconSpan}
        <input className={className}
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
      </div>
      <div className="my-invalid-feedback">
        {touched ? error : ''}
      </div>
    </div>
  );
}

const renderHalfWidthField = field => {
  const icon = `fa fa-${field.faIcon}`;
  const { meta: { touched, error } } = field;
  const className = `form-control form-control-lg ${touched && error ? 'is-invalid' : ''}`
  return (
    <div className="form-group col-lg-6">
      <label>{field.label}</label>
      <div className="input-group">

        <span className="input-group-prepend input-group-text" id="basic-addon1"><i className={icon}></i></span>
        <input className={className}
          type={field.type}
          pattern={field.pattern}
          placeholder={field.placeholder}
          {...field.input}
        />
      </div>
      <div className="my-invalid-feedback">
        {touched ? error : ''}
      </div>
    </div>
  );
}

const renderSizeDropdown = field => {
  const icon = `fa fa-${field.faIcon}`;
  const { meta: { touched, error } } = field;
  const className = `form-control form-control-lg ${touched && error ? 'is-invalid' : ''}`
  return (
    <div className="form-group col-lg-12">
      <label>{field.label}</label>
      <div className="input-group">

        <span className="input-group-prepend input-group-text" id="basic-addon1"><i className={icon}></i></span>
        <select className={className}
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        >
          <option disabled value="">Choose size</option>
          <option value="studio">Studio</option>
          <option value="1br">1 bedroom</option>
          <option value="2br">2 bedrooms</option>
          <option value="3br">3 bedrooms</option>
          <option value="4br">4+ bedrooms</option>
        </select>
      </div>
      <div className="my-invalid-feedback">
        {touched ? error : ''}
      </div>
    </div>
  );
}

// used for the car fields which are rendered in separate columns. Doesn't include icons or validation. 
const renderFullInHalfWidthField = field => {
  return (
    <div className="form-group"
      style={{ paddingLeft: '5px', paddingRight: '5px', width: '100%' }}>
      <label>{field.label}</label>
      <input className="form-control form-control-lg"
        type={field.type}
        placeholder={field.placeholder}
        {...field.input}
      />
    </div>
  );
}

/* formatDate(value) {
   return moment(value);
 }
 normalizeDate(value){
   return value.value.format();
 }
 */


let MoveNewForm = props => {
  const {
    handleSubmit,
    initialValues,
    showVehicles,
    editting,
    onEdit
  } = props;
  //<div style={{backgroundImage: 'url("http://www.surfingvancouverisland.com/surf/photos2010/101027acker01.jpg")'}}/>
  // <img src="http://www.surfingvancouverisland.com/surf/photos2010/101027acker01.jpg"/>
  return (
    <div id="move-form" className="bg-light text-dark pb-5 pt-2">
      <div className="container">
        <div className="row my-card">
          <div className="col-md-5 no-padding">
            <div className="form-img" />
          </div>


          <form id="my-form" className="col-md-7 move-form" onSubmit={handleSubmit}>
            <h3>Save over 65% on your move - $699 deals.</h3>
            <subtitle className="text-muted">Almost there - this is the final step! </subtitle>
    
            <div className="form-row mt-2">
              <Field
                label="Full name"
                name="name"
                placeholder="John Smith"
                type="text"
                faIcon="user"
                component={renderFullWidthField}
              />

              <Field
                label="Email address"
                name="email"
                placeholder="john@example.com"
                type="email"
                faIcon="envelope"
                component={renderFullWidthField}
              />
              <Field
                label="Phone number"
                name="phone"
                placeholder="xxx-xxx-xxxx"
                type="tel"
                faIcon="phone"
                normalize={normalizePhone}
                component={renderFullWidthField}
              />


              <Field
                label="Estimated Move Date"
                name="moveDate"
                placeholder="Move Date"
                type="date"
                faIcon="calendar"
                component={renderFullWidthField}
              />



            </div>

            {editting && <div className="form-row">

              <Field
                label="Move Size"
                name="size"
                placeholder="Move Size"
                type="text"
                faIcon="arrows-alt"
                component={renderSizeDropdown}
              />

              <Field
                label="From Zip Code"
                name="fromZip"
                placeholder="12345"
                type="number"
                pattern="\d*"
                faIcon="map-marker"
                normalize={normalizeZip}
                component={renderHalfWidthField}
              />
              <Field
                label="To Zip Code"
                name="toZip"
                placeholder="54321"
                type="number"
                pattern="\d*"
                faIcon="map-marker"
                normalize={normalizeZip}
                component={renderHalfWidthField}
              />
            </div>
            }

            <div className="mb-3">
              <Field
                name="showVehiclesCheck"
                id="showVehiclesCheckBox"
                component="input"
                type="checkbox"
              />
              <span className="px-2"> I also want to move my car </span>
            </div>


            {showVehicles &&
              <div className="form-row">
                <div className="col-lg-6">
                  <div className="form-row">
                    <Field
                      label="Make (Vehicle 1)"
                      name="make1"
                      placeholder="Honda"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Model (Vehicle 1)"
                      name="model1"
                      placeholder="Civic"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Year (Vehicle 1)"
                      name="year1"
                      placeholder="2012"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-row">
                    <Field
                      label="Make (Vehicle 2)"
                      name="make2"
                      placeholder="Toyota"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Model (Vehicle 2)"
                      name="model2"
                      placeholder="Camry"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Year (Vehicle 2)"
                      name="year2"
                      placeholder="2008"
                      type="text"
                      component={renderFullInHalfWidthField}
                    />
                  </div>
                </div>

              </div>
            }

            <button type="submit" className="btn btn-danger btn-lg btn-block mb-4">Get Free Quotes Now!</button>

            {!editting && <div>
              <div className="form-row mb-2">
                <h5>Your Move Details</h5>
                <a href="" class="text-primary ml-auto" onClick = {onEdit}>Edit</a>
              </div>

              <div className="form-row">
                <p className="col-sm-4 lead">{`Move Size: ${initialValues.size}`}</p>
                <p className="col-sm-4 lead">{`From Zip: ${initialValues.fromZip}`}</p>
                <p className="col-sm-4 lead">{`To Zip: ${initialValues.toZip}`}</p>
              </div>
            </div>
            
            }

          </form>
        </div>
      </div>
    </div>
  );
}

Date.prototype.toDateInputValue = (function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
});

MoveNewForm = reduxForm({
  validate,
  form: 'MoveNewForm',
})(MoveNewForm);

const selector = formValueSelector('MoveNewForm');

MoveNewForm = connect(state => {
  const showVehicles = selector(state, 'showVehiclesCheck');
  var values = {
    fromZip: state.zip.data ? state.zip.data.fromZip : "",
    toZip: state.zip.data ? state.zip.data.toZip : "",
    size: state.zip.data ? state.zip.data.size : "2br",
    moveDate: (new Date(Date.now() + 12096e5)).toDateInputValue()
  }
  return {
    showVehicles,
    initialValues: values
  }
}, null)(MoveNewForm);

export default MoveNewForm;

