import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>MovingTips</h1>

        Our moving tips section supplies extremely valuable information about different aspects of relocation. 
        With the help of this section, you can ensure a hassle-free moving, any place and any time.
        <br /> <br />

        <ul>
          <li><a href='/moving/top_ten_moving_tips'> Top 10 things to do DURING your move!</a> </li>
          <li><a href='/moving/best_moving_offers'> Are your moving companies offering you the best coverage possible?</a> </li>
          <li><a href='/moving/moving_senior_citizens'> Moving wih seniors </a> </li>
          <li><a href='/moving/moving_fragile'> Moving with those fragile things</a> </li>
          <li><a href='/moving/professional_moving'> Why are professional moving/packing companies a good choice?</a> </li>
          <li><a href='/moving/moving_kids'> Moving with kids</a> </li>
          <li><a href='/moving/moving_houseplants'> Moving your houseplants to your new home</a> </li>
          <li><a href='/moving/loading'> No more loading headache!</a> </li>
          <li><a href='/moving/corporate_relocation'> Corporate relocation tips</a> </li>
          <li><a href='/moving/business_data_relocation'> Saving/storing/retrieving your vital business data during relocation</a> </li>
          <li><a href='/moving/storage_info'> General Storage Information </a> </li>
          <li><a href='/moving/types_of_moves'> Different types of moves</a> </li>
          <li><a href='/moving/moving_checklist'> Making a checklist before moving</a> </li>
          <li><a href='/moving/moving_stages'> What should I do before, during, and after moving day?</a> </li>
          <li><a href='/moving/moving_time'> Choosing the best moving time</a> </li>
          <li><a href='/moving/getting_moving_estimate'> Getting an estimate for moving</a> </li>
          <li><a href='/moving/avoiding_rogue_movers'> How to avoid rogue movers</a> </li>
          <li><a href='/moving/moving_tax'> Is moving tax deductible?</a> </li>
          <li><a href='/moving/technical_equipment'> Moving technical equipment</a> </li>
          <li><a href='/moving/payment_claims'> Payment and claims</a> </li>
          <li><a href='/moving/laws_consumer'> Laws that protect the consumer</a> </li>
          <li><a href='/moving/insurance'> Moving company insurance</a> </li>
          <li><a href='/moving/long_distance'> Long distance moving companies - service at your doorstep </a> </li>
          <li><a href='/moving/piano_mover'> An experienced piano mover makes all the difference</a> </li>
          
        </ul>

      </div>
    </div>
  );
}