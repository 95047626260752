import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Settling into a new home</h1>

        Moving into a new house and a new neighborhood is a very special but physically and emotionally taxing event in any individual’s life. 
        Shifting or transporting articles from one place to another can be a hassle in normal circumstances with people requiring help from all 
        and sundry to make things ‘move’. But even before that starts the ordeal of having to pack every little thing in the house into appropriate 
        boxes according to the fragility and the kind of items being packed. Despite having many friends more than eager to lend a helping hand, 
        people are usually apprehensive about the safety of their belongings due to which such offers of help, though sorely required, are politely 
        refused. This is where the professional packers and movers come handy.
        <br/><br/>

        Packers and movers are professional companies which help people make the transition into a new life easier. 
        They ensure that the things which need to be transported reach their destination in perfect condition.
        <br/><br/>

        The real fun and the ‘new beginning’ begin after this moving has taken place. Apart from the challenge of trying to let go of the memories 
        of the older home, settling into a new place and interacting and trying to be friends with strangers; 
        a major source of tension for any newly shifted individual or family would be to set up the new house in the way they desire. 
        Unpacking the furniture and placing things in their appropriate place is another pain which packers and movers can ease as they 
        place all the items in the appropriate places. Some packers also undertake the insurance of the goods during transit on behalf of their 
        customers, saving a lot of hassle. Since this is such an important consideration, people are advised to plan their shifting in advance 
        and choose trusted movers who also understand that every customer has his unique needs making the process of settling into a new home easier.

      </div>
    </div>
  );
}