import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Getting an estiamte for moving</h1>

        When you decide to move, certain questions prop up in your mind straightaway. How can you find a trustworthy mover and how much will it cost?
        <br/><br/>

        You may not know when or where you are moving; still you can approach a mover and ask him just how much it will approximately cost. You can ask your friends and colleagues for referring a good mover to you. Get estimates from several movers well in advance. At this, they will send you a representative to talk about your needs and prepare an estimate. Then you can compare the costs and select one accordingly. 
        <br/><br/>

        It is better to get rid of all belongings that you don’t propose to move and mark them as such. You must know exactly to where you are moving to and fix a probable time and date for the same. Do you need an estimate for packing the items too and if you need space for storing them and for how long?
        <br/><br/>

        You must know what questions you are going to ask the mover when you meet him. Make an appointment with the movers to come to your house. Ask them the same questions and give them exactly the same information. This will help you evaluate their credentials and costs, making it easier to come to a final decision.
        <br/><br/>

        The representative will state his company’s track records, the kinds of services they provide and their policies. He may also provide you with information on packing and moving.
        <br/><br/>

        Now is the time to ask the questions prepared in advance. Ask about their experience, rates and enquire how they move special items like objects of art, pianos and the like. What kind of services do you need? Does it include packing, storage or moving? 
        <br/><br/>

        Take the representative on a tour of your home. Then the person can estimate the weight and quantity and the kind of boxes necessary. If any goods need crating or special packing, the representative ought to tell you. Don’t leave out any part of the house including the attic and the basement while taking him around.
        <br/><br/>

        Ask him or her for the time it takes to make an estimate. Will it be binding or unbinding? If it is a binding estimate, the total expenses for the move is guaranteed depending on the estimated quantities and particular services. Whereas a nonbinding estimate is the mover’s probable final cost which will be calculated again with actual figures at the time of moving.
        <br/><br/>

        Once the estimate is at hand look for the following:
        <br/><br/>

        What are the kind of boxes and packing materials necessary plus the quantities and costs? If you need help with packing and unpacking for specified items ask them for their rates and cost of any additional services. 
        <br/><br/>

        Insurance costs and coverage details for the same and last but not least get their payment terms.
        <br/><br/>

        After carefully going through the details of the estimate ask them jut how long it is valid. Compare all the estimates, noting carefully such points like cost of packing material, rates of labor, volume, weight and mileage. Do they provide any discount items like packing supplies and storage? If you need clarification on any point ask them for explanations. You must make a fair comparison among all those companies. And negotiate for the best terms and price.
        <br/><br/>

        Your criteria for the final selection should not be the lowest cost alone. Make an evaluation of the companies by considering such points like personal referrals, discounts offered, the integrity of the company and your own experience in dealing with them so far.
        <br/><br/>
        
        Signing the moving estimate means you are ordering them to do the work as prescribed. Finding the best moving company is in itself the best award on your moving day.
      </div>
    </div>
  );
}