import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Packing Tips</h1>

        Our packing tips section supplies extremely valuable information about different aspects of packing. 
        With the help of this section, you can ensure a hassle-free packing, any place and any time.
        <br /> <br />

        <ul>
          <li><a href='/packing/packing_household_items'> Packing tips for major household items </a> </li>
          <li><a href='/packing/what_to_keep'> What to keep and what to throw away </a> </li>
          <li><a href='/packing/storing_belongings'> Store your belongings in proper order </a> </li>
          <li><a href='/packing/packing_materials'> Important packing materials </a> </li>
          <li><a href='/packing/general_packing_tips'> General packing tips </a> </li>
          <li><a href='/packing/settling_new_home'> Settling into a new home </a> </li>
          <li><a href='/packing/packing_kitchen_dining'> Packing up your kitchen/dining room </a> </li>
          <li><a href='/packing/packing_living'> Packing up your living room </a> </li>
          <li><a href='/packing/packing_bedroom_bathroom'> Packing up your bedroom and bathroom </a> </li>
        </ul>

      </div>
    </div>
  );
}