import React from 'react'; 

import packingImg from '../images/packing.jpg';
import movingImg from '../images/moving.jpg';
import storageImg from '../images/storage.jpg';

export default(props) => {
  return(
    <div className = 'white_panel'>
      <div className = 'container panel'>
        <h2>Advice on Packing, Moving, and Storage</h2>
        
        <div className="row justify-content-md-center">
          <div className="col-md-3">
            <a href = '/packing'> <img src={packingImg} className = 'tips_img' alt="Packing Icon"/> </a>
            <p> Tips on professional packing and how to save time and money on packing. </p>
          </div>

          <div className="col-md-3">
            <a href = '/moving'> <img src={movingImg} className = 'tips_img' alt="Moving Icon"/> </a>
            <p> Relocation advice to help you save on moving costs. </p>
          </div>

          <div className="col-md-3">
            <a href = '/storage'> <img src={storageImg} className = 'tips_img' alt="Storage Icon"/> </a>
            <p> Advice on how to pick safe and cheap storage to fit your needs. </p>
         </div>


        </div>

      </div>
    </div>
  );
}