import React from 'react'; 

export default(props) => {
  return(
    <div id="thanksBanner" className = "bg-light text-center text-dark py-5">
      <div className = "container panel">

        <h1><i className="fa fa-check-circle-o text-success"></i></h1>
        <h1>Thank you!</h1>
        Your form has been successfully submitted. Our fully licensed and insured movers will contact you soon.

        </div>
    </div>
  );
}