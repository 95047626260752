import React from 'react'; 

export default(props) => {
  return(
    <div id="privacy" className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Privacy Policy</h1>

        <p> 
        MerakMoving.com is committed to respecting your privacy. We want to provide a safe, secure user experience. 
        We will use our best efforts to ensure that the information you submit to us remains private and is used only 
        for the purposes stated below. This notice reflects our commitment to you and applies to all information 
        collected or submitted on the MerakMoving.com web site. By visiting MerakMoving.com, you are accepting the 
        practices described in this notice. 
        </p>
        
        <h3>What Information Do We Collect At MerakMoving.com?</h3>
        
        <h5>General Information </h5>
        <p>
        We automatically gather information about all users collectively, such as, for example, what areas of MerakMoving.com 
        users visit most frequently, what web sites they came from, what computer platform they use and what services on 
        MerakMoving.com they access most frequently. <br /> <br />
        This data helps us determine what is most beneficial for our users and how to continually create a better overall 
        experience for them. This data does not include personally identifying information, and we use this general information only in 
        aggregate form. 
        </p>

        <h5>Personal Information </h5>
        <p>
        In order to provide some of the services on MerakMoving.com, we ask you to provide personal information such as, for example, 
        your name, e-mail address, home address, move date and account numbers. In addition, from time-to-time, we may ask you for additional 
        information, such as, for example, information and feedback about your interests and opinions. We only collect this personal information if 
        you provide it to us voluntarily. <br /> <br />
        We use the information we collect for a variety of purposes, such as responding to your requests, customizing your experience on 
        our web site, improving our services, and communicating with you about your account and about products and services that 
        we think will be of interest to you. 
        </p>

        <h5>Online Security </h5>
        <p>
        We use Secure Socket Layer 128-bit encryption technology and a secure internal computer system to protect personal identifying information 
        used on MerakMoving.com. While this technology does not completely guarantee the security of your information, it does make it very difficult 
        for information to be stolen or intercepted while being transferred and stored. 
        </p>

        <h5> Cookies </h5>
        <p>
        A "cookie" is an element of data that a web site can send to a browser, which then may get stored on a computer. The cookie can be used 
        to identify a repeat user to the site that placed the cookie on the computer. Cookies also can be used to track your movement from page 
        to page, or within a single page of a web site. Some of MerakMoving.com's web pages use cookies. For example, you receive a cookie when 
        you access the Move Planner and Address Changer. <br /> <br />
        MerakMoving.com uses cookies because, among other things, they allow us to personalize content, banners, and promotions that you see on our 
        site. We also use information obtained from cookies to learn how you navigate through MerakMoving.com. <br /> <br />
        You can set your browser software to notify you when receiving a cookie, and you have the option not to accept cookies at all. Because 
        some of our services require the use of cookies, if you refuse to accept cookies or if you remove a cookie that is on your computer, 
        it will affect your ability to log into certain areas and your ability to use certain of the tools and content on MerakMoving.com. 
        </p>

        <h5> Contact Us </h5>
        <p>
        MerakMoving.com encourages you to give us feedback. Please click here to submit your feedback. Any feedback that is submitted becomes the 
        property of MerakMoving.com. Feedback, such as customer suggestions or testimonials, may be used for a variety of purposes, including, 
        for example, our marketing purposes or to contact users about our site. <br /> <br />
        
        Update effective March 10, 2018
        </p>

      </div>
    </div>
  );
}