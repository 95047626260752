import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Packing up your bedroom and bathroom</h1>

        <b>Clothing, Mattresses, and Mirrors</b>
        <ul>
          <li>Use garment boxes or wardrobe cartons for moving clothing. If you don’t want to pay extra for these, fold all of the clothes, and place them in carton. The carton should be lined with wrapping paper to avoid any damage.</li>
          <li>Mattresses and mirrors will be moved by your mover. They have special cartons to keep these safe from damage and clean.</li>
          <li>For hats, wrap each individually and place wrapping paper inside the hat. Pack hats in their own carton, separate from any other goods. Hats can also be moved in wardrobe cartons.</li>
        </ul>

        <b>Jewelry:</b>
        <ul>
          <li>*Important* Keep all jewelry in your possession. If you don’t want to keep it with you, use an armored vehicle for transport. Do not let your mover handle precious goods. *Important*</li>
        </ul>

        <b>Toiletries:</b>
        <ul>
          <li>If you move these, make sure they are wrapped very, very tightly to prevent any leakage.</li>
          <li>Do not move any spray cans because they are often flammable.</li>
          <li>Try to dispose of the liquid materials instead of taking them with you because they can cause damage to your other goods.</li>
        </ul>

        <b>Electronics:</b>
        <ul>
          <li>Televisions should be crated and moved by your mover.</li>
          <li>Stereos should be wrapped well and placed in separate cartons.</li>
          <li>DVD and CD players should have their lasers secured before moving. This precaution does not need to be taken for VCRs.</li>
          <li>Make sure all CDs and DVDs are inside a well cushioned box that is labeled with the word “FRAGILE”.</li>
        </ul>

      </div>
    </div>
  );
}