import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Making a checklist before moving</h1>

        While moving from one location to another it is necessary to have a moving checklist. This is not only convenient but will provide you with a basis to organize the materials that are to be packed. Since the packaging companies are responsible for the transport of your belongings it is necessary that a checklist of all the belongings is maintained so that when your belongings have been shifted to the new location you can recheck by the checklist if all the belongings have arrived.
        <br/><br/>

        Making a checklist should be according to the criteria of the items that are to be shifted. It is important that you divide the items in their separate categories. These categories can be as follows:-
        <ul>
          <li>Furniture</li>
          <li>Crockery</li>
          <li>Clothing</li>
          <li>Stationeries</li>
          <li>Electronics</li>
        </ul>

        These are just some of the categories that you can list the items under. Packaging companies find the checklist important for their operations as it enables them to recheck the item once they have been delivered at the destination. Moreover if the items are being transported overseas the packagers provide you insurance on the goods. But incase items are lost; they can be rechecked with the checklist as the packers will know which are the items that are missing.
        <br/><br/>

        In order to guarantee quality services packers need information into the items that are going to be moved. With this information packers can estimate the time of moving the items efficiently. They can concentrate in packing those items that would be difficult to move. Once the difficult items have been moved packers can concentrate on smaller items so as to enable faster movement of the items.
        <br/><br/>
        
        Therefore in order to safeguard that your items are moved safely from one location to another, it is important to make a checklist which will enable better security for the items that are to be shifted.
      </div>
    </div>
  );
}