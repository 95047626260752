import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>What to keep and what to throw away</h1>

        Are you stuck deciding whether to keep something or get rid off it? Look here for some tips.
        <br/><br/>
        Ask yourself a few questions like:
        <ul>
          <li>How much is the item worth? Should I throw it away or try to sell it?</li>
          <li>Does the item have any sentimental worth?</li>
          <li>Do I really need it? Or do I barely use it?</li>
          <li>When was it last used? Can I remember? </li>
          <li> Where did I get the item from? Was it a gift? </li>
          <li> Did I buy the item from another country? </li>
          <li> Could the item be antique? Collectable? </li>
          <li> Could I have won the item from a contest? </li>
        </ul>

        <br/>

        After asking yourself these questions, you will probably be able to better decide whether or not you want to keep the item. 
        If you want to get rid of it, you face some more choices:
        <ul>
          <li> What’s it worth? </li>
          <li> Is it really worth selling? </li>
          <li> Where should I sell it? EBay? </li>
          <li> Could I have a garage sale? </li>
          <li> Should I give it away? </li>
          <li> Should I donate it to a charitable organization? </li>
        </ul>
        <br/> 
        After figuring out what the item is worth, you can choose to throw it away, sell it, give it to a family friend, or donate it.
          Any old household items could be given away or sold. But, most people decide to give old clothing to charity. Anything that is 
        collectable or antique might as well be kept. After looking through this guide, you should have gotten rid of some things. 
        This helps you tremendously because now you will save more money! 


      </div>
    </div>
  );
}