import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving with seniors</h1>

        On the day of the move, it is important to take special care of all the family members travelling, particularly children and senior citizens. In this way you can be sure that they are safe. For senior citizens within the family, special arrangements must be made to ensure they have a comfortable journey.
        <br/><br/>

        Generally, seniors have certain medications they need to take and prescriptions that they need filled. Sometimes, senior citizens are on a special diet appropriate to their medication; therefore, you must make sure that on the day of the move their meal is packed for the travel. If the plan is to leave early in the morning and they are prescribed some medicines that need to be taken in the morning after breakfast, it is important to allow them to have their breakfast and rest a bit before starting the journey. It is advisable for senior citizens to get a thorough check up done before they travel, just to be sure that there aren’t any complications.
        <br/><br/>

        Some senior citizens require the use of a walking stick or during travel might require a few pillows to avoid any discomfort. Care must be taken that such things are kept handy and not packed away in any of the boxes.
        <br/><br/>
        
        Health insurance cover is another important aspect that needs to be covered for moving with senior citizens. All documentation related to their health must be kept just in case they are required during the trip. For the same purpose, emergency contact numbers of close relatives and friends should also be kept.
        <br/><br/>

        It is always a good idea to take an extra pair of clothes for the journey along with other appropriate clothing that is suitable for the weather such as sweaters, windbreakers, scarves, ear muffs, etc.
      </div>
    </div>
  );
}