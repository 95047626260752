import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>General storage information</h1>

        During the course of your move, you may decide that you need storage.  Some movers offer a storage facility to make your move a little less complicated. There should be storage facilities somewhere in your city as well. You can place some of your belongings inside a storage facility and you can pick them up when you have moved to your city. This will come in handy if you do not have a permanent home at your destination and choose to live in a smaller residence for a short period of time. Also keep in mind that you can keep your belongings stored for a long period of time if it is required. Here are some things to know about storage.

        <ul>
          <li>Make sure you know how secure the storage facility is.</li>
          <li>Does it have wired fences?</li>
          <li>Does it have an adequate amount of alarms?</li>
          <li>Does it look professionally run?</li>
          <li>How secure do you want your belongings to be? For household items, you will not need a security as secure. This will make storage cheaper and will often make it more convenient because you are more likely to find a less secure facility closer to your home.</li>
          <li>You should be familiar with the mover’s (or person offering the storage’s) settlement dispute policies. Although it is rare that you should lose your belongings, you should still be aware of the policy to make sure that you will be reimbursed.</li>
        </ul>

        So, by now you should have decided whether you want storage or not. But, you are not sure where you want to store them. Here are some things to ask your self before choosing a storage facility.

        <ul>
          <li>How far away is it? A facility closest to your home will be more convenient to you.</li>
          <li>How expensive is the storage facility?</li>
          <li>How secure is the storage facility? Choose a facility based on how secure you really want your belongings. A shirt with no sentimental value doesn’t need to be stored in a place with an Area 51 like security system.</li>
          <li>How big is the offered storage unit? Find a storage unit that is big enough for a certain group of items. Make sure that it is not too big so you don’t end up paying extra for space you don’t use.</li>
          <li>Make sure you have read the insurance policies and settlement dispute policies. Do they seem fair?</li>
          <li>Do your belongings need to remain in an area with a certain climate? For example, instruments made of wood will be damaged in low temperatures or humidity. Other things may also be affected so you may want to choose a place with climate control.</li>
        </ul>
     
      </div>
    </div>
  );
}