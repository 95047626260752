import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>What should I do before, during, and after moving day? </h1>

        <b>Getting Prepared for the Move:</b>
        <ul>
          <li>Make sure that everything is packed and ready to go. Anything that you wished to pack on your own should be packed well. The mover will pack everything that you would prefer to pay them to pack.</li>
          <li>Throw all of the items that you have decided that you are not going to move away. By reducing the amount of items, you reduce the weight of the items. This reduces the cost of your move.</li>
          <li>Make sure you have every receipt and form that you have filled out that is related to the move. This will help you make sure that you are moving at the correct price and everything will be moved correctly.</li>
        </ul>  
        <b>Moving Day Has Arrived:</b>
        <ul>
          <li>Make sure that you are at home when the movers arrive. Try to be home to sign final documents regarding the move. If this is not possible, someone else should be there to sign the papers for you.</li>
          <li>Give all phone numbers to the mover. Make sure the driver can contact you at any time if something occurs.</li>
          <li>Make sure the driver knows exactly how to get from your old home to your new one. Also, make sure that the driver gets there with the least possible mileage because that will save you money.</li>
          <li>Tell your mover exactly where to place your things in your new home. If you can provide a blueprint or plan of your house and explain to them where the furniture needs to go, it will save time for you and your mover.</li>
          <li>Check any paperwork. Make sure that everything is ready. Double check the mover’s settlement dispute policy.</li>
          <li>Be sure you have your credit card, cash, or your preferred payment method ready as soon as the mover has delivered your items. If you don’t make sure that you have this, you may be penalized and may have to pay extra.</li>
          <li>Check the inventory sheet. Make sure all of your items were delivered and are in the same condition. When you are signing it, treat it as a receipt.</li>
        </ul>

        <b>After Moving Day:</b>
        <ul>
          <li>Get familiar with your neighborhood.</li>
          <li>Make sure you know where all of the local facilities are. You should know where hospitals, police stations, fire stations, and etc are located.</li>
          <li>Find out the location of essential shopping destinations. Make sure you know where grocery stores, libraries, and etc. are.</li>
          <li>Find health specialists. Make sure you know where doctors, dentists, and etc are.</li>
        </ul>

      </div>
    </div>
  );
}