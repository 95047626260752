import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving company insurance</h1>

        Often, there are instances when people wonder whether they should adopt insurance after hiring a professional moving company or not. As we know, insurance is a special facility that ensures complete security to your belongings. Therefore, at times when any professional mover is held responsible for handling your belongings, it is better to opt for insurance to avoid unavoidable or accidental losses. You can insure your possessions either through the movers, or separately, as per your exact needs and the nature of the objects that would be moved.
        <br/><br/>

        They say “Prevention is better than cure”. Though a cliché, it holds significant value in terms of avoiding future disasters. Such is the case as well when you decide on making moving company insurance decisions.
        <br/><br/>

        Primarily, there is need to establish agreement between you and the mover, concerning the insurance policy. It is highly advised not to hire those companies which refrain from offering any kind of insurance. For verification purpose, request for documents and contracts viewing, in order to go through the items covered, and the type of liability that is offered. For instance, few moving companies do not include those items which were unpacked by the movers.
        <br/><br/>

        Documentation reading and verbal dialogues with the company help you understand the degree at which the insurance is provided, as each moving company generally offers different degree of insurance. On occasions when you are not allowed to read the fine print, it is advisable to obtain services from some other reliable moving company.
        <br/><br/>

        Another important aspect to be considered while dealing with moving company insurance is that you have to be acquainted with the worth of your belongings. This is because, based on your estimation, the moving company will cover the insurance for your belongings. A lower estimation might cause difficulties at times of damage, by forgoing the benefit; while a higher estimation would need you to cash out extra money from your pocket unnecessarily.
        <br/><br/>

        You will notice that most of the moving companies offer premium coverage together with the basic amount. This premium is provided for the benefit of those who are in constant apprehension regarding the replacement costs of their belongings. On the other hand, the basic doesn’t do much except for providing you with something little for your effort. Opting for the premium option ensures security when you are skeptical about the handling of valuable items, by the moving company. If not premium, then you can also locate your own coverage from an outside source to blend together with the moving company’s basic policy.
        <br/><br/>

        It is totally up to you to decide whether you require insurance beyond the basic policies of the moving company. To obtain extra coverage, perform comparison shopping. Also, you can pick and select the items that you wish to be covered under premium policies. Getting your valuables covered and not concerning about additional insurance on your basic instruments may prove to be very effective in cutting down your insurance costs and securing your belongings.
        <br/><br/>

        However, remember that all the moving companies do not always offer you the best premium policy deal. Hence, try to acquire more options from your insurance provider for meeting your moving company insurance requirements.
        <br/><br/>

        <b>There are three different ways of choosing your insurance.</b>
        
        <ul>
          <li>Declared Value: In this format, the insurance is decided by weight. For example, if you are covered for $.5 per pound for 15,000 pounds, then you are covered for $7,500 (.5 X 15,000). The mover will have to pay you up to $7,500 for any losses. You will get the depreciated cost of an item. Suppose you have a 2 year old computer and it is lost. The mover will calculate the depreciation and then pay you.</li>
          <li>Lump Sum Value: In this format, you declare the value of your belongings. The value can be no more than 6 X the total weight. Any items over a certain amount are placed in a separate list. This will only be offered in state to state moves.</li>
          <li>Full Protection: This format covers almost any losses that may occur. In this format, you will be paid for the replacement or repair of your item. Let’s take the 2 year old computer case. If you paid for a Full Protection plan, you would be paid for a new computer instead of the cost of a depreciated computer.</li>
        </ul>
        
        <b>Advice:</b>
        
        <ul>
          <li>Take photos of expensive items in order to show the condition they were in.</li>
          <li>Make sure the cartons are packed very well. If a carton is not damaged from the outside, but something is damaged inside, you will not be compensated.</li>
          <li>The above only goes for what you have packed, not the mover.</li>
          <li>Follow our packing guide.</li>
          <li>If anything has sentimental value, don’t move it with the mover. IF something happens, you will not be paid much for it and it’s something you cannot get back.</li>
          <li>Make sure you know the exact worth of any expensive items because you will have to note this down beforehand and this is necessary to get a claim.</li>
          <li>Try getting an insurance claim as soon as possible.</li>
        </ul>
      </div>
    </div>
  );
}