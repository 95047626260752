import axios from 'axios';

export const CREATE_MOVE_REQUEST = "CREATE_MOVE_REQUEST"; 
export const LOAD_MOVE_FORM = "LOAD_MOVE_FORM";

const ROOT_URL = 'https://pekkjmpinh.execute-api.us-east-1.amazonaws.com/prod'

export function loadMoveForm(values) {

  return {
    type: LOAD_MOVE_FORM,
    payload: values
  }; 

}

export function createMoveRequest(values, callback) {

  var request = "";

  const options = {
    headers: {'x-api-key': "g3Da984LW097js6jWXHmUvQPVEynN515RnRX2abf"}
  };
  
  if ((values.showVehiclesCheck === undefined) || (values.showVehiclesCheck === false) ||
    (values.make1 === undefined && values.make2 === undefined)){

    request = axios.post(`${ROOT_URL}/res-leads`, values, options)
      .then(callback);

  } else {

    request = axios.all([
      axios.post(`${ROOT_URL}/res-leads`, values, options),
      axios.post(`${ROOT_URL}/auto-leads`, values, options)
    ]).then(callback);

  }
  
  return {
    type: CREATE_MOVE_REQUEST,
    payload: request
  }
}