import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving technical equipment</h1>

        Moving technical equipment from your office to another can pose many problems. Maximum care should be taken whenever such equipment has to be removed. Such equipment is usually very expensive and if something untoward happens and they are damaged they might cost you a good deal to be replaced. This does not include any income that may be lost till the equipment is replaced satisfactorily.
        <br/><br/>

        Besides these, moving may present other problems. You may use your employees to move the equipment from place to another. But you should ensure that you are not acting contrary to some part of your contract that states that the supplier’s workers move the equipment. The mantra is when in doubt, seek the aid of a reputable office mover.
        <br/><br/>

        In equipment like a copier or printer, some parts may poke out from its body. Be sure to take them off first before you attempt to shift them , as there is a tendency for them to bend or break in the process. Usually such parts are easy enough to remove and reassemble and by doing this you can avoid any possible need to procure new equipment. Thus moving is made much easier and less time consuming.
        <br/><br/>

        Before moving any electronic equipment like computer systems, it is necessary to disconnect all cables before removing the peripherals and you must do this at lease 24 hours in advance so that they will be at room temperature on moving day. Moving electronic equipment in which heat is still retained may cause internal damage. If at all for some reason you cannot do this disconnect them from the mains one day ahead and don’t let the chords hang loose while computers, monitors and the like are being taken from one place to another, making the whole exercise cumbersome and awkward.  Instead secure them with strings or tape to one of the units. Inattention can cause the computers to be broken as well as bodily injuries to the personnel moving them. And if your equipment is particularly valuable it is always better to consult a professional mover who understands the intricacies of the situation better. The end product is peace of mind, safety and security. 
        <br/><br/>

        Also there is a chance for all the valuable data stored in your computers to be lost in the course of moving. So you must make sure to back up all data before packing and moving them. One better way to pack them is to use the original packages and pad them at the bottom and all around. You must pack them in tight-fit boxes. The same should be done to the printer and all other peripherals. Other electronic equipment should also be similarly packed.
        <br/><br/>
        
        Finding a trustworthy moving company can take away much from the stress of moving. Find someone who is experienced in moving electronic and technical equipment among other things. Do some research, ask around and get everything from estimates to contracts written down and each clause agreed with in advance so that everything goes smoothly without any hitches.
      </div>
    </div>
  );
}