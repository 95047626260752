import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving with those fragile things</h1>

        Whereever you look, you might just see something that is 'Oh so precious'. A little something that you got from your partner, or your graduation gift from your parents, or that precious little plant you sowed on your fifth birthday and have had it ever since. A home is full of such precious memories that are absolutely irreplaceable in a life time, and it is these memories that demand a lot of special care and attention!
        <br/><br/>

        We know that moving is hard and at many times you’re going to wish you had more hands to help you. It doesn’t matter how well everything is packed, you’re still going to worry about your precious memories. Most of your things will be under insurance but, some things have sentimental value and can’t be measured by their price.
        <br/><br/>

        Most of the packing and moving companies do not provide an insurance cover for plants because of their vulnerability to damage. And therefore it becomes the prime responsibility of the owner to make sure that the plants are appropriately packed and special instructions have been given to the packers and movers to take every effort to minimize any kind of damage to them. It is advisable to avoid carrying large clay pots which can crack easily and damage plants. Before the move, make sure the plants are well watered, treated with pesticide, and are in a pot that fits their size. Larger pots pose the threat of the plant moving within thus, damaging the roots of the plant.
        <br/><br/>

        It may be hard to plant all of your plants immediately when you move. Experts suggest that for the garden plants, a hole can be dug and the plants can be placed in it . Covering the roots to protect them and frequent watering until one finds the time to plant them properly is a good idea for the first few days.
        <br/><br/>

        Electronics are another form of fragile items that require special item. It’s smart to pack these in the exact boxes they came in. Those boxes were designed for their safe transport across a distance along. Just in case those boxes and their internal padding have been thrown away, they must be packed in boxes of similar size with a lot of cushioning in order to prevent any kind of damages. Most of the transport companies provide such boxes. While moving computers care must be taken to see that there is a separate physical backup of all the data on all the drives on separate CD’s or DVD’s just in case the hard drives(s) are damaged in transit. An old floppy disc must be inserted into the floppy drive to prevent any other foreign particles from entering the slot.
      </div>
    </div>
  );
}