import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Laws that protect the consumer</h1>
        <ul>
          <li>There are a respectable number of laws that protect the consumer. But, many of these laws also have a negative aspect. Make sure you understand your rights before you move in order to avoid any scams or problems with your move.</li>
          <li>The mover and the customer both have rights that the customer should be aware of. There is a book of optional guidelines called the 400 tariff published by the American Moving and Storage Association that many movers follow. It is often helpful to find a mover that follows these guidelines. This ensures that the consumer is protected and gets the best service possible. Some of the things it guaranteed are:</li>
          <li>Movers must give the consumer a written estimate</li>
          <li>Movers may give the consumer a binding estimate ( See the moving hints section for details on binding estimates )</li>
          <li>The Consumer may request the availability of Pick Up and Delivery Dates</li>
          <li>The Consumer may ask for the shipment to be reweighed</li>
          <li>Movers must offer a dispute settlement program to settle any claims of damage or loss</li>
          <li>Movers must offer a minimum insurance on all property ( Cost: $.60 per pound )</li>
          <li>The Carmack Amendment guarantees that the mover must pay for any losses caused by theft or negligence. They must pay the cost of any damages or for lost property.</li>
          <li>Although this seems like a law that might help the consumer, there is also a negative aspect to this. If any of your materials are lost or damaged, the mover ONLY has to pay for the lost or damaged property. The consumer would also have to file a case against the moving company in this situation. If the mover loses or steals $2000 worth of your property and you have to pay $5000 for the case, you lose money while the company only has to give back what it took and nothing more. This really is not a large consequence for many moving companies. Especially because many people wouldn’t bring it to court. Make sure you ask your mover about their dispute settlement program to avoid a scenario like this.</li>
          <li>The Household Good Transportation Act of 1980 allowed movers to make their own prices. This created competition and started binding estimates (See the moving hints section for details on binding estimates). This allowed stiffer competition and helped lower the cost for consumers. Also, with binding estimates, movers are not able to “lowball” estimates.  </li>
          <li>If you choose to ask for a non binding estimate, the mover may “lowball”. This basically means that the mover will give you a lower estimate. This could entice you into agreeing to the move. In the end, the mover could increase the price by a decent some of money and you would be unable to do anything about it. Look at our moving hints section for more details on estimates and make sure you get the best possible estimate.</li>
        </ul>
      </div>
    </div>
  );
}