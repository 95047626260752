import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Store your belongings in proper order</h1>

        While moving to a new location, you also tend to choose the new storage of your miscellaneous belongings. 
        Both moving and finding a store for your belongings go side by side. Moving companies also provide for a storage capacity 
        to store your household goods therein, but you need to pay extra charges to avail their storage building. 
        Alternatively, you will be required to rent a self-storage unit for your goods. A self-storage unit is basically a boxy, 
        mini-warehouse building often located in the highways worldwide.
        <br/> <br/>

        Renting a private self-storage unit is pretty safe and easier. You just have to open the door, 
        put your belongings inside and shut the door with a lock and key. But public self-storage units are not as 
        secured and personalized as the private ones are. You may never know what’s happening with your belongings 
        inside the public self-storage units. Therefore, it is essential to choose the right self-storage facility 
        and once you select the best one, it also becomes necessary to pack your self storage unit in correct order.
        <br/><br/>

        Before you select a self-storage facility, you ought to choose the location first, the prime factor in the process. 
        If you want the storage facilities to be used on a regular note, you will require one close to your home for convenience 
        purposes. If you are engaging into a long distance moving and so wish to have storage somewhere on your route to the 
        final destination, you can look for a reputable and reliable storage facility.
        <br/><br/>

        Secondly, you need to estimate the space you require for your goods for storing in. There are different sizes available 
        in self-storage facilities. Small storage units are better to opt for, so that no space is left untouched and that 
        your money does not go waste. Mini-storage facilities are also available that specialize in small loads and can be opted 
        for if you find the small storage units to be large for you.
        <br/><br/>
        
        Ask your agent about the access time permit for your storage units. While some self-storage facilities allow 24 x 7 
        free access to you, some of them hold certain restrictions, and a few even charge fees for access. Make sure your 
        belongings are kept in units that adjust with climatic conditions to avoid any spoilage or loss of valuable or delicate 
        items such as antiques. Some facilities fetch extra charges to provide you with a climate-controlled unit.
        <br/><br/>

        Remember not to keep any flammable or combustible items in your storage unit, such as gasoline, oil, fluids and so on. 
        Avoid storing hazardous chemicals or fuel in your self-storage unit.

      </div>
    </div>
  );
}