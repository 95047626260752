import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Packing up your kitchen/dining room</h1>

        <b>*IMPORTANT:</b> Make sure to write “Fragile” on any box that contains fragile materials. 
        Also, we suggest writing “Kitchen Supplies” or “Appliances” on the boxes depending on what they contain.
        <br/><br/>

        <b>Flat China or Glass:</b>

        <ul>
          <li> Place wrapping paper on the bottom of a box or carton. This will provide extra cushioning on the bottom of the box.</li>
          <li> Place a plate (or any form of flat china or glass) in the center of another sheet of wrapping paper. Grasp a corner of 
          your paper and pull it over the plate . Grasp a second corner and pull that over the plate. Repeat this procedure for two more plates.</li>
          <li> Stack the three plates (or any form of flat china or glass) on top of each other and wrap them with another sheet of wrapping paper.</li>
          <li> Seal your bundle with plastic tape.</li>
          <li> Repeat the above 3 steps for as many bundles as you can fit in one layer on the box. </li>
          <li> After one layer has been filled, cover the layer with more wrapping paper and make sure no wholes or creases are visible. 
          You can choose to fill the rest of the box with pots and pans or maybe even glasses.</li>
          <li> If you choose to fill the box with plates, repeat the process for as many layers as you can until the box is filled to capacity.</li>
        </ul>

        <b>* Note:</b> When using smaller pieces of flat china or glass, a bundle can consist of up to 5 items.
        <br/><br/>

        <b>Silverware and Flatware:</b>

        <ul>
          <li>Our advice is to individually wrap each piece of silverware or flatware with wrapping paper. Secure the silverware and flatware with tape.</li>
          <li>Place the individually wrapped pieces of silverware in a smaller container or box.</li>
        </ul>

        <b>Glasses/Cups:</b>
        <ul>
          <li>Wrap each glass individually with wrapping paper. For certain types of glasses, you can place a wrapped glass inside another and stack 3 or 4 glasses in one bundle.</li>
          <li>Place them in a box with dividers in order to keep them safe from damage. A liquor box would be perfect in this situation. Add wrapping paper on top of the glasses in order to fill the box to capacity.</li>
          <li>If you do not have liquor boxes, put your glasses with your other dishes, but make sure they are towards the top. We highly recommend you to get liquor boxes to save time and make packing easier.</li>
        </ul>
        
        <b>Small Appliances:</b>
        <ul>
          <li>If the appliance has cords, secure the cords to the appliance and fasten them with tape. Wrap all appliances individually with 3 or 4 sheets of wrapping paper. Make sure the cords are wrapped in a way that won’t scratch/damage the appliance in any way.</li>
          <li>Place the appliances in a separate container from other kitchen supplies; it will make it easier to distinguish from plates, glasses, and etc. Write “Appliances” on their respective boxes.</li>
        </ul>

        <b>Pots, bowls and Pans:</b>
        <ul>
          <li>Wrap each pot/pan/bowl individually.</li>
          <li>Bowls can be nested together and packed similarly to plates.</li>
          <li>If you choose to add these items to your plates, make sure they are above the first layer of plates. Other wise stack them together in bundles and pack them in a similar method to plates.</li>
        </ul>

        <b>Small Figurines:</b>
        <ul>
          <li> Wrap each figurine separately and place them inside a carton. If possible, place them inside a smaller box first. Be sure that you have written “FRAGILE” on the boxes clearly.</li>
          <li>If anything is very fragile, consult your mover and they will pack/move it for you..</li>
        </ul>

      </div>
    </div>
  );
}